.BackgroundFullSize {
    width: 100%;
    height: 100%;
    min-height: calc(100vh);
    //position: absolute;
    position: fixed;
    z-index: 1;
    top:0px;
    left:0px;

    &:after{
        content:'';
        position: absolute;
        top:0px;
        left:0px;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.5;
        transition: 1s ease-in-out;

    }

    &:hover:after{ 
        opacity: 0.45;
    }

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}