.FieldSelect {
    box-sizing: border-box;

    &.hasContent .select-container{
        border: 1px solid #757575;
    }

    &.isError .select-container{
        border-color:#ED5052; 
    }

    .select-container{
       
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #DBDBDB;
        padding: 10px;
        border-radius: 4px;
        background-color: white;
        display: flex;
        justify-content:  space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        transition: 0.25s;
        position: relative;

        .click-area{
            border-radius: 4px;
            background-color: transparent;
            width: 100%;
            height: 100%;
            z-index: 1;
            position: absolute;
            left:0px;
        }

           
        input{
            border-radius: 4px;
            padding: 0px;
            border-radius: 0px;
            border:none;
            font-size: 16px;
            font-family: 'Roboto';
            width: calc(100% - 24px);

            z-index: 2;
            position: relative;

            &:focus{
                outline: none;
            }
            &.multiple{
                width: calc(100px);
            }
        }

        svg{
            max-width: 24px;
            z-index: 2;
            position: relative;
        }
        
    }

}