.MuiStepConnector-root{

    .MuiStepConnector-line{
        z-index:1;
        @media screen and (min-width: 899px){
            //transform: scaleX(1.5);
        }
    }
}

.MuiStepLabel-root{
    .MuiStepLabel-iconContainer{
        z-index:2 ;
        opacity: 1;
    }

}