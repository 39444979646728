.PolitiqueDonnees {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.25;
    font-family: Roboto;

    overflow-wrap: anywhere;

    p, ul li{
        color:#616161;
    }

    h1{
        
        margin-bottom: 10px;
    }

    h2{
        margin-top: 20px;
        margin-bottom: 20px;
    }

    h3{
        margin-bottom: 5px;
    }

    p{
        margin: 20px 0px;
    }

    ol li{
        font-weight: 600;
        color:black;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    ul li{
        margin-top: 15px;
        margin-bottom: 15px;
    }

    strong{
        color:black;
    }

    .MuiTableContainer-root{
        margin-top: 30px;
        margin-bottom: 30px;
    }

    a{
        color:black;
        font-weight: 400;
    }
}