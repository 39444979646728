*{
    box-sizing: border-box !important;
}

::-ms-reveal {
    display: none;
}

.MuiButton-root.MuiButtonBase-root{
    //box-shadow: none !important;
}
