$size-menu : 320px;

.app-layout{
    display: flex;
    height: 100vh;
    overflow: hidden;
    padding:0px;
    background-color: #FAFAFA;
    .sidebar{
        display: block;
        width: $size-menu;
        height: 100%;
        padding: 0px;
        z-index: 100;

        @media screen and (max-width: 1000px){
           width:270px;
        }

        &.open{
            height: 100vh;
        }

        @media screen and (max-width: 899px){
            background-color: blue;
            height: fit-content;
            width: 100vw;
            position: fixed;
        }



    }
    .container{
        background-color: #FAFAFA;
        width: calc(100vw - $size-menu);
        @media screen and (max-width: 1000px){
            width: calc(100vw - 270px);
         }
        height: 102vh;
        padding: 0px;
        margin: 0px;
        overflow: scroll;

        @media screen and (max-width: 899px){
            width: 100vw;
            padding-top: 120px;
        }

        .content{
            padding: 80px 100px;
            box-sizing: border-box;
            min-height:calc(100vh - 100px);
            max-width: 1200px;
            margin: auto;

            @media screen and (min-width: 900px) and (max-width: 1200px){
                padding: 40px 50px;
            }

            @media screen and (max-width: 899px){
                padding: 40px 16px;
                width: 100vw;
            }

  
            &.loading{
                display: flex;
                align-items: center;
                justify-content: center;
            }

        }
    }


}