.MentionsLegales {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.25;
    font-family: Roboto;

    overflow-wrap: anywhere;

    p, ul li{
        color:#616161;
    }
}