.TextFieldCustom {
    box-sizing: border-box;
    box-sizing: border-box;

    label{
        text-align: left;
    }

    &.isError .input-area{
       border-color:#ED5052 !important; 
    }

    &.readOnly .input-area{
        background-color: #F3F3F3;
        border: 1px solid #DBDBDB;
        color: #616161;
        padding:12px;
    }

    &.hasContent .input-area{
        border: 1px solid #757575;
 
    }

    .input-area{
        width: 100%;
        height: 44px;
        padding:0px;
        border:1px solid #DBDBDB;
        border-radius:4px;
        padding-right: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        transition-duration: 0.25s;
        //overflow-y: scroll;
    }
    .MuiButtonBase-root{
        padding: 0px;
    }
    .MuiInput-root,.inputChildren{
        width: 100%;

        input{
            border-radius: 0px;
            border:none;
            width: 100%;
            //height: 20px;
            //height: 100%;
            height: 41px;
            padding:12px;
            border-radius: 4px;
            font-size: 16px;
            font-family: 'Roboto';
            &:focus{
                outline: none;
            }
            
        }

        input[type=date]{
            background-color: transparent;
        }
    }
    
}