.FieldTextarea {
    textarea{
        width: 100%;
        height: 50px;
        border:#C2C2C2 1px solid;
        border-radius: 6px;
        font-family: 'Roboto';
        padding: 10px;
        font-size: 16px;
    }
}