.etapes-container{
    position: relative;

    .line{
        width: 100%;
        height: 1px;
        background-color: #757575;
        
        position: absolute;
        top:calc(50% - 0.5px);
        z-index:2;

        @media screen and (max-width: 899px){
            height: 100%;
            width: 1px;
            left:calc(50% - 0.5px);
            top:0px;
        }
    }

    .etape{
        z-index: 3;
    }
}
